.contact-us {
    background: #559e96;
    padding: 60px 0 60px;
    color: #fff;
    h2 {
        color: #fff;
    }
    a {
        color: #fff;
    }
    .bullet-list > li:before {
        background: #fff;
    }
    @media (max-width: 767px) {
        padding: 40px 0 30px;
    }
}