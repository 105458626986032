.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: all 0.2s linear;
    text-shadow: 0 1px 4px #000;
    &.fixed-top {
        background: #559e96;
    }
    @media (max-width: 767px) {
        padding: 5px 0;
    }
}