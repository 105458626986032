.number-list {
    counter-reset: item;
    list-style: none !important;
    > li {
        counter-increment: item;
        position: relative;
        padding-left: 29px;
        margin-bottom: 15px;
        &:before {
            content: counter(item)". ";
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    @media (max-width: 767px) {
        > li {
            padding-left: 19px;
        }
    }
}

.bullet-list {
    > li {
        position: relative;
        padding-left: 35px;
        list-style: none;
        margin-bottom: 15px;
        &:before {
            position: absolute;
            top: 7px;
            left: 13px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #000;
            content: '';
        }
    }
    @media (max-width: 767px) {
        > li {
            padding-left: 19px;
            &:before {
                left: 2px;
            }
        }
    }
}