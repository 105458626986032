.logo {
    margin: 0 auto 35px;
    display: block;
    width: 250px;
    img {
        width: 100%;
    }
    @media (max-width: 767px) {
        width: 150px;
    }
}