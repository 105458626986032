.new-arrivals {
    padding-top: 60px;
    padding-bottom: 60px;
    &-gallery {
        padding: 0 60px;
        .slick-arrow {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            border: none;
            background: none;
            font-size: 0;
            line-height: 0;
            width: 40px;
            height: 40px;
            z-index: 10;
            &:before {
                font-family: 'icomoon-custom';
                font-size: 40px;
                color: #559e96;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "\ea44";
            }
            &.slick-next {
                left: auto;
                right: 0;
                &:before {
                    content: "\ea42";
                }
            }
        }
    }
    &-item {
        margin: 10px;
        display: block;
        border: 1px solid #559e96;
        border-radius: 10px;
        overflow: hidden;
        height: 300px;
        position: relative;
        box-shadow: 0 0 5px #559e96;
        strong {
            font-size: 24px;
            line-height: 1.2;
            font-weight: 700;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            width: 100%;
            text-align: center;
            padding: 0 15px;
            z-index: 2;
            transition: all 0.2s linear;
            opacity: 0;
            color: #fff;
        }
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-height: 100%;
            width: auto;
            max-width: none;
            max-height: 300px;
            transition: all 0.2s linear;
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            transition: all 0.2s linear;
            background: rgba($color: #559e96, $alpha: 0.6);
            content: '';
        }
        .no-touch & {
            &:hover {
                img {
                    transform: translate(-50%, -50%) scale(1.05);
                }
                strong,
                &:before {
                    opacity: 1;
                }
            }
        }
    }
    @media (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 40px;
        &-gallery {
            padding: 0;
        }
    }
}