@font-face {
  font-family: 'icomoon-custom';
  src:  url('../fonts/icomoon-custom.eot?h37s3f');
  src:  url('../fonts/icomoon-custom.eot?h37s3f#iefix') format('embedded-opentype'),
    url('../fonts/icomoon-custom.ttf?h37s3f') format('truetype'),
    url('../fonts/icomoon-custom.woff?h37s3f') format('woff'),
    url('../fonts/icomoon-custom.svg?h37s3f#icomoon-custom') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-custom-"], [class*=" ico-custom-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-custom' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-custom-circle-right:before {
  content: "\ea42";
}
.ico-custom-circle-left:before {
  content: "\ea44";
}
.ico-custom-facebook2:before {
  content: "\ea91";
}
.ico-custom-instagram:before {
  content: "\ea92";
}
.ico-custom-twitter:before {
  content: "\ea96";
}
.ico-custom-youtube:before {
  content: "\ea9d";
}
.ico-custom-linkedin:before {
  content: "\eac9";
}
