.footer {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-logo {
        width: 70px;
    }
    &-list {
        display: flex;
        padding: 0 20px;
        text-align: center;
        li {
            padding: 0 10px;
            position: relative;
            &:not(:first-child) {
                &:before {
                    position: absolute;
                    top: 50%;
                    left: -2px;
                    transform: translate(0, -50%);
                    content: '|';
                }
            }
        }
        a {
            color: #000;
        }
    }
    @media (max-width: 1023px) {
        flex-wrap: wrap;
        position: relative;
        padding-left: 140px;
        justify-content: center;
        &-logo {
            margin-right: 40px;
            position: absolute;
            top: 40px;
            left: 40px;
        }
        &-list {
            padding: 0;
            margin: 0 0 10px;
        }
    }
    @media (max-width: 767px) {
        padding: 20px;
        flex-direction: column;
        &-logo {
            position: static;
            margin: 0 auto 20px;
        }
        &-list {
            flex-direction: column;
            align-items: center;
            margin: 0 0 20px;
            li {
                &:before {
                    display: none;
                }
            }
        } 
    }
}