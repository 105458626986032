.social-networks {
    display: flex;
    margin: 0 -5px;
    li {
        padding: 0 5px;
    }
    a {
        display: flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #559e96;
        text-decoration: none;
        .no-touch & {
            &:hover {
                color: #f98383;
            }
        }
    }
}