.hero {
    position: relative;
    overflow: hidden;
    min-height: 500px;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba($color: #559e96, $alpha: 0.6);
        content: '';
    }
    &-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        max-width: none;
    }
    &-content {
        position: relative;
        z-index: 2;
        text-align: center;
        padding-top: 120px;
        padding-bottom: 70px;
    }
    h1 {
        color: #fff;
        margin: 0 0 30px;
        text-transform: uppercase;
        font-size: 40px;
        line-height: 1.1;
        font-weight: 400;
        letter-spacing: 2px;
        font-family: "Bebas Neue";
        text-shadow: 0 0 2px #559e96;
    }
    h2 {
        color: #fff;
        margin: 0 0 20px;
        font-size: 25px;
        line-height: 1.1;
        font-weight: 600;
        text-shadow: 0 0 4px #559e96;
    }
    @media (max-width: 767px) {
        min-height: 0;
        &-content {
            padding-top: 50px;
            padding-bottom: 35px;
        }
        h1 {
            font-size: 36px;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
}