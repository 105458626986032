body {
	min-width: 320px;
	font: 18px/1.2 'Lato', helvetica, sans-serif;
	color: #000;
	@media (max-width: 767px) {
		font-size: 16px;
	}
}

button,
[type=reset],
[type=submit],
[type=image],
a {
	-webkit-transition: background-color .2s, color .2s;
	transition: background-color .2s, color .2s;
}

a {
	color: #000;
	.no-touch & {
		&:hover {
			text-decoration: none;
		}
	}
}

img {
	display: block;
	height: auto;
	max-width: 100%;
}

b,
strong {
	font-weight: 700;
}

i,
em {
	font-style: italic;
}

p {
	margin: 0 0 15px;
}

%list-reset {
	list-style: none;
	margin: 0;
	padding: 0;
}

%clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.container {
	max-width: 1360px;
	margin: 0 auto;
	padding: 0 40px;
	position: relative;
	@media (max-width: 767px) {
		padding: 0 20px;
	}
}

.text-center {
	text-align: center;
}

h2 {
	text-transform: uppercase;
	font-size: 34px;
	line-height: 1.1;
	color: #000;
	font-weight: 700;
	margin: 0 0 30px;
	font-family: "Bebas Neue";
	letter-spacing: 2px;
	@media (max-width: 767px) {
		font-size: 30px;
		margin-bottom: 20px;
	}
}