.main-nav {
    &__list {
        display: flex;
        justify-content: center;
        li {
            padding: 0 20px;
        }
        a {
            color: #fff;
            text-transform: uppercase;
            padding: 20px 0;
            display: block;
            font-size: 18px;
            font-weight: 500;
            text-decoration: none;
            .no-touch & {
                &:hover {
                    color: #f98383;
                }
            }
        }
    }
    &-opener {
        width: 40px;
        height: 40px;
        background: none;
        border: none;
        position: relative;
        display: none;
        margin-left: auto;
        &:before {
            width: 30px;
            height: 4px;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            background: #fff;
            content: '';
        }
        span {
            width: 30px;
            height: 22px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &:before,
            &:after {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                width: 30px;
                height: 4px;
                background: #fff;
                transition: all 0.3s linear;
                content: '';
            }
            &:after {
                top: 18px;
            }
        }
    }
    @media (max-width: 767px) {
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        background: #559e96;
        transform: translate(100%, 0);
        transition: all 0.3s linear;
        padding: 0 0 20px;
        &-opener {
            display: block;
        }
        &__list {
            flex-direction: column;
            a {
                padding: 10px 0;
            }
        }
    }
}

.menu-opened {
    .main-nav {
        transform: translate(0, 0);
        &-opener {
            &:before {
                display: none;
            }
            span {
                &:before {
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                &:after {
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
    }
    .header {
        background: #559e96;
    }
}