*,
*:before,
*:after {
  box-sizing: border-box;
  max-height: 100000px;
  outline: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
  padding: 0;
  text-transform: none;
  color: inherit;
  border-radius: 0;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  overflow: visible;
  cursor: pointer;
  -webkit-appearance: button;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input[type="email"],
input[type="number"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  overflow: auto;
  resize: none;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-placeholder {
  opacity: 1;
  color: inherit;
}

:-ms-input-placeholder {
  color: inherit;
}
